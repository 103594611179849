<template>
  <v-container fluid class="pa-0 white">
    <div
      id="panelOverlay"
      v-if="displayDrawer"
      style="
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0.5;
        z-index: 0;
      "
      @click="displayDrawer = false"
    />
    <headerToolbar color="white">
      <template v-slot:toolbarTitle>
        <b class="ml-3">{{ $t("projects.roadmap") }}</b>
        <projectForm
          @projectCreated="rehydrateOnProjectCreation"
          :editedProject="editedProject"
        />
        <v-btn class="ml-4" small outlined depressed @click="zoomOut()">
          <v-icon size="20" color="mid_grey darken-4"
            >mdi-magnify-minus-outline</v-icon
          >
        </v-btn>

        <v-btn class="ml-4" small outlined depressed @click="zoomIn()">
          <v-icon size="20" color="mid_grey darken-4"
            >mdi-magnify-plus-outline</v-icon
          >
        </v-btn>

        <v-select
          v-model="selectedZoomIndex"
          solo
          flat
          hide-details
          outlined
          dense
          :items="zoomLevels"
          item-text="text"
          item-value="index"
          class="ml-4 reducedVselect caption"
          @change="zoomToSelectedLevel(zoomLevels[selectedZoomIndex])"
        >
        </v-select>

        <!-- displayDrawer: {{ displayDrawer }} -->
      </template>
    </headerToolbar>
    <v-divider></v-divider>

    <!-- <jsonViewer :value="events" /> -->
    <div
      class="pa-0"
      fluid
      style="height: calc(100vh - 36px); overflow: scroll"
    >
      <bryntum-scheduler
        ref="scheduler"
        v-bind="schedulerConfig"
        @datachange="syncData"
        :resources="resources"
        :events="events"
        :startDate="schedulerStartDate"
        :endDate="schedulerEndDate"
        :emptyText="$t('projects.noProjects')"
        :eventRenderer="eventRenderer"
      />

      <v-navigation-drawer
        :value="displayDrawer"
        absolute
        right
        style="z-index: 5"
        temporary
        width="740"
        hide-overlay
        app
        overlay-opacity="0"
        overlay-color="tranparent"
        class="projectSchedulerProjectSidebar"
        stateless
        color="light_grey"
      >
        <schedulerProjectSidebar
          @close="displayDrawer = false"
          :resource="selectedResource"
          @updateProject="updateProject($event)"
          :key="refreshIndex"
          :projects="projects"
          @selectProject="setSelectedResource($event, 'project')"
          @rehydrateProject="rehydrateProject"
          @removeUserFromProject="removeUserFromProject"
          @removeFollowUpFromProject="removeFollowUpFromProject"
          @deleteProject="deleteProject"
          @updateFollowUp="updateFollowUp"
          :followUpIdToHighlight="followUpIdToHighlight"
          @clearHighlightedFollowUp="followUpIdToHighlight = ''"
          @addFollowUpToProject="addFollowUpToProject"
        />
      </v-navigation-drawer>
    </div>
  </v-container>
</template>

<script>
import headerToolbar from "../shared/headerToolbar";
import date from "@/mixins/date";
import { mapActions, mapGetters } from "vuex";
import projectForm from "@/components/projects/projectForm";
import { BryntumScheduler } from "@bryntum/scheduler-vue";
import { Toast, StringHelper, Tooltip } from "@bryntum/scheduler";
import PAGINATED_PROJECTS_INDEX from "@/graphql/projects/paginated_index.gql";
import schedulerProjectSidebar from "@/components/projects/schedulerProjectSidebar";
import schedulerProjectSidebarHeader from "@/components/projects/schedulerProjectSidebarHeader";
import {
  AjaxHelper,
  DateHelper,
  DateField,
  ResourceModel,
  EventHelper,
} from "@bryntum/scheduler";

import { schedulerConfig } from "@/plugins/projectsSchedulerConfig";
import UPDATE_PROJECT from "@/graphql/projects/update.gql";
import UPDATE_FOLLOW_UP from "@/graphql/follow_ups/update.gql";
import followup_status from "@/mixins/followup_status";
ResourceModel.childrenField = "follow_ups";

export default {
  name: "roadmap",
  components: {
    headerToolbar,
    projectForm,
    BryntumScheduler,
    schedulerProjectSidebar,
    schedulerProjectSidebarHeader,
  },
  mixins: [date, followup_status],
  data() {
    return {
      forceUpdate: false,
      colors: [
        "red",
        "pink",
        "purple",
        "indigo",
        "blue",
        "cyan",
        "teal",
        "green",
        "lime",
        "yellow",
        "orange",
      ],
      resources: [],
      followUpShowId: "",
      events: [],
      schedulerConfig,
      displayDrawer: false,
      followUpIdToHighlight: "",
      selectedResourceId: "",
      selectedResource: {
        data: {
          users: [],
        },
        type: "project",
      },
      search: "",
      refreshIndex: 0,
      editedProject: {},
      projects: [],
      loading: false,
      schedulerStartDate: new Date(+new Date() - 1209600000),
      schedulerEndDate: new Date(+new Date() + 1209600000 * 4),
      zoomLevels: [
        {
          level: "weekAndDay",
          text: this.$t("misc.zoomLevels.weekAndDay"),
          index: 0,
          tickSize: 110,
        },
        {
          level: "weekAndDayLetter",
          text: this.$t("misc.zoomLevels.weekAndMonth"),
          index: 1,
          tickSize: 32,
        },
        // {
        //   level: "weekAndDayLetter",
        //   text: this.$t("misc.zoomLevels.weekAndDayLetter"),
        //   index: 2,
        // },
        // {
        //   level: "weekDateAndMonth",
        //   text: this.$t("misc.zoomLevels.weekDateAndMonth"),
        //   index: 3,
        // },
        {
          level: "monthAndYear",
          text: this.$t("misc.zoomLevels.monthAndYear"),
          index: 2,
          tickSize: 240,
        },
        {
          level: "year",
          text: this.$t("misc.zoomLevels.year"),
          index: 3,
          tickSize: 110,
        },
      ],
      selectedZoomIndex: 2,
    };
  },
  computed: {
    ...mapGetters(["selectedOrganisationId"]),
    followUps() {
      return this.projects.map((e) => e.follow_ups).flat();
    },
  },
  methods: {
    ...mapActions(["openDestroyDialog"]),
    followUpToResource(f) {
      return {
        id: f.id,
        name: f.name,
        color: "white",
        startDate: f.start_date,
        endDate: f.end_date,
        type: "follow_up",
        //status: f.status, // Careful if uncommenting : this create an error into rehydrateFollowUp
      };
    },
    projectToResource(e) {
      return {
        id: e.id,
        name: e.name,
        title: e.name,
        expanded: false,
        type: "project",
        numberOfFollowups: e.follow_ups.length,
        color: e.color,
        eventColor: this.fixColor(e.color),
        startDate: e.start_date,
        endDate: e.end_date,
        follow_ups: e.follow_ups.map((f) => {
          return this.followUpToResource(f);
        }),
      };
    },
    buildResources() {
      this.resources = this.projects.map((e) => {
        return this.projectToResource(e);
      });
    },
    followUpToEvent(f, p) {
      return {
        id: f.id,
        resourceId: f.id,
        startDate: f.start_date,
        endDate: f.end_date,
        type: "follow_up",
        name: f.name,
        color: "white",
        eventColor: this.fixColor(p.color),
        status: f.status,
      };
    },
    projectToEvent(p) {
      return {
        id: p.id,
        resourceId: p.id,
        startDate: p.start_date,
        endDate: p.end_date,
        type: "project",
        eventColor: this.fixColor(p.color),
        color: this.fixColor(p.color),
        name: p.name,
        eventStyle: "border",
      };
    },
    buildEvents() {
      this.events = this.projects
        .map((p) => {
          return [
            p.follow_ups.map((f) => {
              return this.followUpToEvent(f, p);
            }),
            this.projectToEvent(p),
          ].flat();
        })
        .flat();
    },
    syncData({ store, action, records }) {
      console.log(
        `${store.id} changed. The action was: ${action}. Changed records: `,
        records
      );
      // Your sync data logic comes here
    },
    setDefaultTimeRange() {},
    editProject(project) {
      this.editedProject = {};
      this.$nextTick(() => {
        this.editedProject = project;
      });
    },
    fixColor(color) {
      if (color.includes("#")) return "blue";
      return color;
    },
    colorToRGBA(hexcolor, alpha) {
      var c;
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hexcolor)) {
        c = hexcolor.substring(1).split("");
        if (c.length == 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = "0x" + c.join("");
        return (
          "rgba(" +
          [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
          "," +
          alpha +
          ")"
        );
      }
      throw new Error("Bad Hex");
    },
    goToProject(event) {
      //this.$router.push({
      //  name: "project_show",
      //  params: { id: event.id },
      //});
    },
    deleteProject(project) {
      this.openDestroyDialog({
        id: project.id,
        model: "project",
        onDestroy: (project_id) => {
          //this.getProjects();
          /*
            Remove project from
              projects
              bryntum resource
              bryntum event
            refresh selected resource
          */
          let index = this.projects.findIndex((e) => e.id === project_id);
          if (index < 0) return;
          this.projects.splice(index, 1);

          let scheduler = this.$refs.scheduler.schedulerInstance;

          let event = scheduler.eventStore.getById(project_id);
          if (event) event.remove();

          let resource = scheduler.resourceStore.getById(project_id);
          if (resource) resource.remove();

          if (this.selectedResourceId == project_id) {
            this.displayDrawer = false;
            this.setSelectedResource("", "");
          }
        },
        title: this.$t("projects.removeAlertTitle"),
        message: this.$t("projects.removeAlertMessage").replace(
          "<project_name>",
          project.name
        ),
      });
    },
    rehydrateOnProjectCreation(project) {
      let scheduler = this.$refs.scheduler.schedulerInstance;
      this.projects.push(project);
      scheduler.resourceStore.add(this.projectToResource(project));
      scheduler.eventStore.add(this.projectToEvent(project));
      this.displayDrawer = true;

      this.setSelectedResource(project.id, "project");
    },
    findResourceIndex(project_id) {
      let scheduler = this.$refs.scheduler.schedulerInstance;
      for (let record of scheduler.resourceStore) {
        if (record.id == project_id) return record.parentIndex;
      }
      return this.projects.length;
    },
    addFollowUpToProject({ follow_up, project_id }) {
      /*
        Add the new follow up to
          project.follow_ups
          bryntum resource
          bryntum event
        refresh selected resource    */
      let project = this.projects.find((e) => e.id === project_id);
      if (!project) return;

      console.log("Adding follow up to project ", follow_up.id, project_id);
      project.follow_ups.push(follow_up);

      const scheduler = this.$refs.scheduler.schedulerInstance;
      let node = scheduler.resourceStore.getById(project_id);
      node.appendChild(this.followUpToResource(follow_up));
      scheduler.eventStore.add(this.followUpToEvent(follow_up, project));
      node.numberOfFollowups = node.children.length;
      scheduler.expand(node);
      this.followUpIdToHighlight = follow_up.id;
    },
    removeFollowUpFromProject({ project_id, follow_up_id }) {
      /*
        Remove follow up from
          project.follow_ups
          bryntum resource
          bryntum event
        refresh selected resource
      */
      let project = this.projects.find((e) => e.id === project_id);
      if (!project) return;
      console.log("removing follow up from project ", follow_up_id, project_id);
      project.follow_ups = project.follow_ups.filter(
        (f) => f.id !== follow_up_id
      );

      let scheduler = this.$refs.scheduler.schedulerInstance;

      let event = scheduler.eventStore.getById(follow_up_id);
      if (event) event.remove();

      let resource = scheduler.resourceStore.getById(follow_up_id);
      if (resource) resource.remove();

      if (this.selectedResourceId == project.id)
        this.setSelectedResource(project.id, "project");
    },
    removeUserFromProject({ project_id, user_id }) {
      /*
        Remove the excluded user from project.users and
        project.project_users (avoiding another query)
        and rehydrate
      */
      let project = this.projects.find((e) => e.id === project_id);
      if (!project) return;
      console.log("removing user from project ", user_id, project_id);
      project.users = project.users.filter((e) => e.id !== user_id);
      project.project_users = project.project_users.filter(
        (e) => e.user_id !== user_id
      );
      this.rehydrateProject(project);
    },
    getProjects() {
      if (this.loading) return;

      this.loading = true;
      this.forceUpdate = true;
      this.cleanSchedulerStore();
      this.refreshIndex++;

      this.$apollo
        .query({
          query: PAGINATED_PROJECTS_INDEX,
          variables: {
            filter: `projects.organisation_id == '${this.selectedOrganisationId}'`,
            page: 1,
            per_page: -1,
            order_by: "id",
          },
        })
        .then(({ data }) => {
          this.loading = false;
          this.projects = data.paginated_projects.data;
          this.$nextTick(() => {
            this.buildResources();
            this.buildEvents();
            this.$nextTick(() => {
              this.refreshIndex++;
            });
          });
          this.refreshSelectedResource();
          this.forceUpdate = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
          this.sendError(e);
          this.forceUpdate = false;
        });
    },
    initSchedulerData() {
      this.getProjects();
    },
    zoomIn() {
      if (this.selectedZoomIndex) this.selectedZoomIndex--;
      this.zoomToSelectedLevel(this.zoomLevels[this.selectedZoomIndex]);
    },
    zoomOut() {
      if (this.selectedZoomIndex < this.zoomLevels.length - 1)
        this.selectedZoomIndex++;
      this.zoomToSelectedLevel(this.zoomLevels[this.selectedZoomIndex]);
    },
    zoomToSelectedLevel(level_obj) {
      // console.log("Zoom to level: ", level);
      const scheduler = this.$refs.scheduler.instance;

      scheduler.zoomToLevel(level_obj.level);
    },
    refreshSelectedResource() {
      if (!this.selectedResourceId) return;
      const selectedProject = this.projects.find((p) => {
        return p.id == this.selectedResourceId;
      });
      this.selectedResource = {
        data: selectedProject,
        type: "project",
      };
    },
    setSelectedResource(id, type, followUpToHighlight = "") {
      console.log(id, type, followUpToHighlight);
      this.selectedResourceId = id;
      if (type == "project") {
        const selectedProject = this.projects.find((p) => {
          return p.id == this.selectedResourceId;
        });
        this.selectedResource = {
          data: selectedProject,
          type: "project",
        };

        this.followUpIdToHighlight = followUpToHighlight;
      }
    },
    initSchedulerListeners() {
      const scheduler = this.$refs.scheduler.instance;

      scheduler.on("eventclick", (e) => {
        // console.log("EVENT CLICK", e);
        if (e.eventRecord && e.eventRecord.type == "project") {
          this.$nextTick(() => {
            this.displayDrawer = true;
            this.setSelectedResource(e.eventRecord.id, "project");
          });
        }

        if (e.eventRecord && e.eventRecord.type == "follow_up") {
          this.$nextTick(() => {
            this.displayDrawer = true;
            this.setSelectedResource(
              this.projectFromFollowUpId(e.eventRecord.id).id,
              "project",
              e.eventRecord.id
            );
          });
        }
      });

      scheduler.eventStore.on("update", (e) => {
        if (!e.record) return;

        let vars = { id: e.record.id };
        if (e.changes.startDate)
          vars.start_date = this.timeToUsDate(e.changes.startDate.value);
        if (e.changes.endDate)
          vars.end_date = this.timeToUsDate(e.changes.endDate.value);

        if (e.record.type == "project") this.updateProject(vars);

        if (e.record.type == "follow_up") this.updateFollowUp(vars);
      });
    },
    cleanSchedulerStore() {
      if (!this.$refs.scheduler) return;
      let scheduler = this.$refs.scheduler.schedulerInstance;
      scheduler.resourceStore.forEach((record) => {
        console.log(record.name);
        record.remove();
      });
      scheduler.eventStore.forEach((record) => {
        console.log(record.name);
        record.remove();
      });
    },
    rehydrateProject(project) {
      let scheduler = this.$refs.scheduler.schedulerInstance;

      let index = this.projects.findIndex((e) => e.id === project.id);
      if (index >= 0) this.projects[index] = project;

      let resource = scheduler.resourceStore.getById(project.id);
      if (resource) resource.set(this.projectToResource(project));

      let event = scheduler.eventStore.getById(project.id);
      if (event) event.set(this.projectToEvent(project));

      project.follow_ups.forEach((f) => {
        let f_resource = scheduler.resourceStore.getById(f.id);
        let f_event = scheduler.eventStore.getById(f.id);
        if (f_resource) f_resource.set(this.followUpToResource(f));
        if (f_event) f_event.set(this.followUpToEvent(f, project));
      });

      if (this.selectedResourceId == project.id)
        this.setSelectedResource(project.id, "project");
      // Probablement de la merde a voir
      // this.buildEvents();
    },
    projectIndexFromFollowUpId(follow_up_id) {
      return this.projects.findIndex((e) =>
        e.follow_ups.map((f) => f.id).includes(follow_up_id)
      );
    },
    projectFromFollowUpId(follow_up_id) {
      let index = this.projectIndexFromFollowUpId(follow_up_id);
      if (index < 0) return null;
      return this.projects[index];
    },
    rehydrateFollowUp(follow_up) {
      let index = this.projectIndexFromFollowUpId(follow_up.id);
      //this.projects.findIndex((e) => e.follow_ups.map(f => f.id).includes(follow_up.id));
      if (index < 0) {
        console.log("error rehydrating follow up, project not found");
        return;
      }
      this.projects[index].follow_ups = this.projects[index].follow_ups.map(
        (f) => {
          if (f.id !== follow_up.id) return f;
          return follow_up;
        }
      );
      let project = this.projects[index];
      let scheduler = this.$refs.scheduler.schedulerInstance;
      /*
        TODO Fix resource rehydratation for follow up adding status field into followUpToResource
      */
      let resource = scheduler.resourceStore.getById(follow_up.id);
      try {
        if (resource) resource.set(this.followUpToResource(follow_up));
      } catch (error) {
        console.log("error on rehydrate follow up resource");
        console.error(error);
      }

      let event = scheduler.eventStore.getById(follow_up.id);
      try {
        if (event) event.set(this.followUpToEvent(follow_up, project));
      } catch (error) {
        console.log("error on rehydrate follow up event");
        console.error(error);
      }
      if (
        this.selectedResourceId &&
        this.selectedResourceId == follow_up.project_id
      ) {
        this.setSelectedResource(follow_up.project_id, "project");
      }
    },
    projectHasChanges(vars) {
      if (this.forceUpdate) return true;

      let project = this.projects.find((e) => e.id === vars.id);
      for (const key in vars)
        if (project[key] != vars[key]) {
          console.log("changes detected  on ", key, project[key], vars[key]);
          return true;
        }
      return false;
    },
    followUpHasChanges(vars) {
      if (this.forceUpdate) return true;

      let follow_up = this.followUps.find((e) => e.id === vars.id);
      if (!follow_up) return true;

      for (const key in vars)
        if (follow_up[key] != vars[key]) {
          console.log("changes detected  on ", key, follow_up[key], vars[key]);
          return true;
        }
      return false;
    },
    updateProject(vars) {
      if (!this.projectHasChanges(vars)) {
        console.log("project has no changes, canceling update");
        return;
      }
      this.$apollo
        .mutate({
          mutation: UPDATE_PROJECT,
          variables: vars,
        })
        .then(({ data }) => {
          Toast.show({
            html: "Project updated!",
            timeout: 1000,
          });
          try {
            this.rehydrateProject(data.update_project);
          } catch (error) {
            console.log("error on rehydrate project");
            //console.error(error);
          }
          //this.sendSuccess(this.$t('projects.updatedProject'));
        })
        .catch((error) => {
          this.sendError(error);
          console.log(error);
        });
    },
    updateFollowUp(vars) {
      if (!this.followUpHasChanges(vars)) {
        console.log("followup has no changes, canceling update");
        return;
      }
      console.log("Update Followup event!", vars);
      this.$apollo
        .mutate({
          mutation: UPDATE_FOLLOW_UP,
          variables: vars,
        })
        .then(({ data }) => {
          Toast.show({
            html: "Follow-up updated!",
            timeout: 1000,
          });
          try {
            this.rehydrateFollowUp(data.update_follow_up);
          } catch (error) {
            console.log("error on rehydrate followup");
            console.error(error);
          }
          //this.sendSuccess(this.$t('projects.updatedProject'));
        })
        .catch((error) => {
          this.sendError(error);
          console.log(error);
        });
    },
    eventRenderer({ renderData, resourceRecord, eventRecord }) {
      if (resourceRecord.isParent) {
        renderData.wrapperCls.add("event-project");
      } else {
        renderData.wrapperCls.add("event-followup");
      }

      const _name = StringHelper.encodeHtml(eventRecord.name);
      var _status = "";

      if (resourceRecord.isParent == false && eventRecord.data.status) {
        const _iconCls =
          "v-icon notranslate pl-2 mdi theme--light v-size--small ";
        const _iconCode = this.getStatus(eventRecord.data.status).icon;
        const _iconColor =
          this.getStatus(eventRecord.data.status).color + "--text";
        const _statusName = StringHelper.encodeHtml(
          this.$t("followUps.status." + eventRecord.data.status)
        );
        _status = `
        <div style="position: absolute; left: 0; transform: translateX(calc(-25px - 100%))">
          <span style="float: right; background-color: white; border-radius: 8px">
            <i style="font-size: 16px;" class="${_iconCls} ${_iconColor} ${_iconCode}"></i>
            <span class="pr-2"  style="font-weight: 400; font-size: 12px !important; color: #222">${_statusName}</span>
          </span>
        </div>
        `;
      }

      return _name + _status;
    },
  },
  mounted() {
    this.setDefaultTimeRange();
    this.zoomToSelectedLevel(this.zoomLevels[this.selectedZoomIndex]);
    this.initSchedulerListeners();
  },
  created() {
    document.title = this.$t("navigationMenu.projects");
    this.$amplitude.getInstance().logEvent("Page: Roadmap");
    this.initSchedulerData();
  },
  watch: {
    organisationFilter() {
      this.getProjects();
    },
    selectedOrganisationId() {
      this.getProjects();
    },
  },
};
</script>

<style>
.theme--light.v-application {
  background-color: white !important;
}

.reducedVselect {
  max-width: 220px;
  min-height: 28px !important;
  height: 28px !important;
}

.reducedVselect .v-input__slot {
  min-height: 28px !important;
  height: 28px !important;
}
</style>

<style lang="scss">
// Comment to force loading of scss. Don't know why it doesnt work without it.
</style>
<style>
.b-sch-event {
  overflow: visible;
}
</style>
<style scope>
.v-navigation-drawer--is-mobile:not(.v-navigation-drawer--close),
.v-navigation-drawer--temporary:not(.v-navigation-drawer--close) {
  box-shadow: none;
}
</style>