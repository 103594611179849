<template>
  <div v-if="resource.data">
    <template v-if="resource.type == 'project'">
      <!-- <v-img :src="bannerUrl" :height="180">
        <v-toolbar color="rgba(0,0,0,0.2)" flat dark dense>
          <v-toolbar-title>{{ resource.data.name }}</v-toolbar-title>

          <v-spacer></v-spacer>
          <v-btn icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>




        </v-toolbar>

      </v-img>
  <v-tabs
            align-with-title
            background-color="transparent"
            v-model="selectedTab"
          >
            <v-tabs-slider :color="resource.data.color"></v-tabs-slider>
            <v-tab href="#project"
              ><v-icon small left>mdi-folder</v-icon
              >{{ $t("projects.project") }}</v-tab
            >

            <v-tab href="#comments"
              ><v-icon small left>mdi-chat-alert</v-icon
              >{{ $t("projects.projectComments") }} (0)</v-tab
            >
          </v-tabs> -->
      <!-- <v-app-bar
        :class="'.b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-'+resource.data.color"
        dark
        flat
        :key="bannerUrl"


  :prominent="true"
        :src="bannerUrl"
        fade-img-on-scroll
        scroll-target="#scrolling-techniques-3"
      >
        <template v-slot:img="{ props }">
          <v-img
            v-if="bannerUrl"
            v-bind="props"
            gradient="to top, rgba(0,0,0,.85) 0 5%, rgba(0,0,0,.1) "
          ></v-img>
        </template>

        <v-app-bar-nav-icon><v-icon>mdi-arrow-right-bold</v-icon></v-app-bar-nav-icon>

        <v-app-bar-title class="text-no-wrap" style="width: 100% !important">{{
          resource.data.name
        }}</v-app-bar-title>

        <v-spacer></v-spacer>


                <v-btn icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <template v-slot:extension>
          <v-tabs
            align-with-title
            background-color="transparent"
            v-model="selectedTab"
          >
            <v-tabs-slider :color="resource.data.color"></v-tabs-slider>
            <v-tab href="#project"
              ><v-icon small left>mdi-folder</v-icon
              >{{ $t("projects.project") }}</v-tab
            >

            <v-tab href="#comments"
              ><v-icon small left>mdi-chat-alert</v-icon
              >{{ $t("projects.projectComments") }} (0)</v-tab
            >
          </v-tabs>
        </template>
      </v-app-bar> -->

      <!-- <v-toolbar dark prominent flat :src="projectPicture">
        <v-app-bar-nav-icon
          ><customIcon
            style="position: relative; top: 3px"
            :color="resource.data.color"
            name="project"
            width="26"
            height="26"
            ><iconProject
          /></customIcon>
          <v-toolbar-title>{{
            resource.data.name
          }}</v-toolbar-title></v-app-bar-nav-icon
        >

        <v-spacer></v-spacer>

        <v-btn icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <template slot="img">
          <v-hover v-if="projectPicture">
            <template v-slot:default="{ hover }">
              <v-card tile flat>
                <v-img
                  max-height="180"
                  :src="projectPicture"
                  :key="bannerRefresh"
                ></v-img>
                <v-fade-transition>
                  <v-overlay v-if="hover" absolute color="#036358">
                    <v-btn
                      dark
                      depressed
                      color="button_blue"
                      @click="removeBanner()"
                      >{{ $t("projects.removeProjectBanner") }}</v-btn
                    >
                  </v-overlay>
                </v-fade-transition>
              </v-card>
            </template>
          </v-hover>
          <uploader
            v-on:upload="setProjectPictureBlob($event.file, $event.blob)"
            v-else
          >
            <template #btn="{ loading }">
              <v-hover v-slot="{ hover }">
                <v-btn
                  tile
                  :loading="loading"
                  class=""
                  color="grey lighten-2"
                  block
                  style="height: 180px"
                  depressed
                >
                  <template v-if="hover"
                    ><span
                      class="font-weight-bold body-2 grey--text text-darken-1"
                      ><v-icon small left>mdi-cloud-upload</v-icon
                      >{{ $t("projects.projectBanner") }}</span
                    ></template
                  >
                  <template v-else
                    ><v-icon size="96" color="grey lighten-4"
                      >mdi-image-area</v-icon
                    ></template
                  >
                </v-btn>
              </v-hover>
            </template>
          </uploader>
        </template>
      </v-toolbar> -->

      <!-- <v-btn small icon class="white almost_black--text elevation-2" style="position: absolute; right: 12px; top: 12px"><v-icon>mdi-close</v-icon></v-btn> -->
      <!-- <v-list-item>
        <v-list-item-icon class="mr-4">
          <customIcon
            style="position: relative; top: 3px"
            :color="resource.data.color"
            name="project"
            width="26"
            height="26"
            ><iconProject
          /></customIcon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="title">{{
            resource.data.name
          }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-tabs
            height="36px"
            background-color="transparent"
            color="button_blue"
            v-model="selectedTab"
            left
            class="px-4"
          >
            <v-tab href="#project"
              ><v-icon small left>mdi-folder</v-icon
              >{{ $t("projects.project") }}</v-tab
            >

            <v-tab href="#comments"
              ><v-icon small left>mdi-chat-alert</v-icon
              >{{ $t("projects.projectComments") }} (0)</v-tab
            >
          </v-tabs> -->
      <!-- <v-btn small icon @click="$emit('close')"><v-icon>mdi-close</v-icon></v-btn> -->
      <!-- </v-list-item-action>
      </v-list-item> -->
    </template>
  </div>
</template>

<script>
import date from "@/mixins/date";
import customIcon from "@/components/custom_icons/customIcon";
import iconFollowup from "@/components/custom_icons/iconFollowup";
import iconProject from "@/components/custom_icons/iconProject";
import uploader from "@/components/shared/uploader";
import REMOVE_PROJECT_PICTURE from "@/graphql/projects/remove_picture.gql";

export default {
  name: "schedulerSidebar",
  components: {
    customIcon,
    iconFollowup,
    iconProject,
    uploader,
  },
  mixins: [date],
  props: {
    resource: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedTab: 0,
      bannerRefresh: 0,
      bannerUrl: "",
    };
  },
  computed: {
    projectPicture() {
      if (this.resource.data.picture_url) {
        return this.resource.data.picture_url;
      }
    },
  },
  methods: {
    setProjectPictureBlob(file, blob) {
      let vars = {
        id: this.resource.data.id,
        picture_signed_blob_id: blob.signed_id,
      };
      this.$emit("updateProject", vars);
      this.loadBanner();
    },
    removeBanner() {
      this.$apollo
        .mutate({
          mutation: REMOVE_PROJECT_PICTURE,
          variables: { id: this.resource.data.id },
        })
        .then(({ data }) => {
          this.resource.data.picture_url = null;
        });
    },
    loadBanner() {
      if (this.resource.data.picture_url)
        this.bannerUrl = this.resource.data.picture_url;
      else this.bannerUrl = "";
    },
  },
  mounted() {
    this.loadBanner();
  },
  created() {},
  watch: {
    resource() {
      this.loadBanner();
    },
  },
};
</script>





<style scoped>
</style>