<template>
  <v-select
    flat
    solo
    class="caption"
    hide-details
    dense
    :value="status"
    :items="status_list"
    item-value="value"
    :item-text="statusText"
    @change="$emit('selectStatus', $event)"
    background-color="transparent"
  >
    <template v-slot:item="data">
      <v-list-item-icon class="mr-1">
        <v-icon small :color="data.item.color">{{ data.item.icon }}</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="font-weight-regular">
          {{ $t("followUps.status." + data.item.value) }}
        </v-list-item-title>
      </v-list-item-content>
    </template>

    <template v-slot:selection="data">
      <v-icon small left :color="data.item.color">{{ data.item.icon }}</v-icon>
      {{ $t("followUps.status." + data.item.value) }}
    </template>
  </v-select>

</template>

<script>
import followup_status from "@/mixins/followup_status.js";

export default {
  name: "followupStatusDropdown",
  mixins: [followup_status],
  props: ["status"],
  data() {
    return {};
  },
  methods: {
    statusText(e) {
      return this.$t("followUps.status." + e.value);
    },
  },
};
</script>
