<template>
  <div>
    <v-simple-table class="px-4" v-if="sortedFollowUps.length">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left px-2">
              {{ $t("followUps.name") }}
            </th>
            <th class="text-left px-2">
              {{ $t("followUps.followUpStatus") }}
            </th>
            <th class="text-left px-2">
              {{ $t("followUps.tasks") }}
            </th>
            <th class="text-left px-2">
              {{ $t("followUps.startDate") }}
            </th>
            <th class="text-left px-2">
              {{ $t("followUps.endDate") }}
            </th>
            <!--
          <th class="text-left px-2">
            {{ $t("followUps.members") }}
          </th> -->

            <th class="text-left px-2">
              {{ $t("misc.actions") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in sortedFollowUps"
            :key="item.id"
            :class="{ highlightedTableRow: followUpIdToHighlight == item.id }"
          >
            <td
              class="px-2 font-weight-bold body-4"
              style="text-decoration: underline"
            >
              <router-link
                :to="{
                  name: 'followup_show',
                  params: { id: item.id },
                }"
                target="_blank"
                >{{ item.name }}</router-link
              >
            </td>
            <td class="px-0 body-4" style="max-width: 150px">
              <followupStatusDropdown
                style="z-index: 10"
                :status="item.status"
                @selectStatus="selectStatus(item.id, ...arguments)"
              />
            </td>
            <td class="px-2 body-4">
              <v-progress-circular
                size="12"
                width="2"
                :color="item.nb_task_done == item.nb_task ? 'success' : 'button_blue'"
                class="mr-2"
                :value="item.nb_task > 0 ? item.nb_task_done / item.nb_task * 100 : 0"
              />
              <span>{{ item.nb_task_done }} / {{ item.nb_task }}</span>
            </td>
            <td class="px-2 body-4">{{ timeToDate(item.start_date) }}</td>
            <td class="px-2 body-4">{{ timeToDate(item.end_date) }}</td>
            <!-- <td>...</td> -->
            <td>
              <contextualMenu
                :actions="contextualMenuActions"
                @actionClic="eventHandler"
                :resourceId="item.id"
              />
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <template v-else>
      <!-- <span class="body-2">
                  {{ $t("followUps.followUpsDescription1") }}<br />
                  {{ $t("followUps.followUpsDescription2") }}
                </span> -->
      <v-alert
        type="info"
        elevation="0"
        class="mx-4 mt-2 mb-2 caption"
        color="purple_alert"
      >
        <span class="caption purple_alert_text--text">
          {{ $t("projects.noFollowUps") }}
        </span>

        <template slot="prepend">
          <v-icon class="mr-4" color="purple_alert_text">mdi-information</v-icon>
        </template>
      </v-alert>
    </template>
  </div>
</template>

<script>
import date from "@/mixins/date";
import users from "@/mixins/users";
import searchAndSelectUsers from "./../users/searchAndSelectUsers";
import userAvatarGroup from "@/components/shared/userAvatarGroup";
import userAvatar from "../shared/userAvatar";
import followupStatusDropdown from "@/components/followups/followupStatusDropdown";
import followup_status from "@/mixins/followup_status.js";
import contextualMenu from "../shared/contextualMenu.vue";
import UPDATE_FOLLOW_UP from "@/graphql/follow_ups/update.gql";

export default {
  name: "project_followups_table",
  components: {
    contextualMenu,
    userAvatarGroup,
    userAvatar,
    searchAndSelectUsers,
    followupStatusDropdown,
  },
  mixins: [date, users, followup_status],
  props: {
    followups: {
      type: Array,
      required: true,
    },
    followUpIdToHighlight: {
      type: String,
      required: false,
      default: "",
    },
    projectId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      contextualMenuActions: [
        {
          name: "edit",
          text: this.$t("misc.edit"),
          icon: "mdi-card-bulleted-outline",
          function: this.openEditDialog,
        },
        {
          name: "remove_from_project",
          text: this.$t("followUps.removeFromProject"),
          icon: "mdi-delete",
          function: this.removeFromProject,
        },
      ],
    };
  },
  computed: {
    sortedFollowUps() {
      return this.followups.sort(this.sortByCreatedAt);
    },
  },
  methods: {
    sortByCreatedAt(a, b) {
      return new Date(b.created_at) - new Date(a.created_at);
    },
    openEditDialog(followUpId) {
      this.$emit(
        "editFollowUp",
        this.followups.find((e) => e.id === followUpId)
      );
      console.log("openEditDialog for ", followUpId);
    },
    removeFromProject(followUpId) {
      console.log("removeFromProject for ", followUpId);
      this.loading = true;
      this.$apollo
        .mutate({
          mutation: UPDATE_FOLLOW_UP,
          variables: {
            id: followUpId,
            project_id: null,
          },
        })
        .then(({ data }) => {
          this.loading = false;
          this.$emit("removeFollowUpFromProject", {
            project_id: this.projectId,
            follow_up_id: followUpId,
          });
        })
        .catch((error) => {
          this.loading = false;
          this.sendError(error);
          console.log(error);
        });
    },
    eventHandler(event, resourceId) {
      event.function(resourceId);
    },
    goToFollowUp(id) {
      this.$router.push({
        name: "followup_show",
        params: { id: id },
      });
    },
    selectStatus(id, status) {
      let vars = {
        status: status,
        id: id,
      };
      console.log("Select Status", vars);
      this.$emit("updateFollowUp", vars);
    },
  },
  mounted() {},
  created() {},
  watch: {},
};
</script>





<style scoped>
</style>