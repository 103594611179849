import { StringHelper, Tooltip } from '@bryntum/scheduler';




let highlight = false,
  center = false;

const schedulerConfig = {

  barMargin: 8,
  rowHeight: 42,
  eventColor: "white",
  eventStyle: "colored",
  eventEdit: false,
  createEventOnDblClick: false,
  infiniteScroll: true,
  snap: true,
  features: {
    eventDragCreate: {
      disabled: true
    },
    rowCopyPaste: {
      disabled: true
    },
    eventCopyPaste: {
      disabled: true
    },
    eventFilter: false,
    eventMenu: {
      disabled: true
    },
    eventContextMenu: {
      disabled: true
    },
    scheduleMenu: {
      disabled: true
    },
    eventEdit: {
      disabled: true
    },
    contextMenu: {
      disabled: true,
    },
    pan: {
      disabled: false
    },
    timeRanges: {
      showHeaderElements: true,
      showCurrentTimeLine: true,
      showCurrentTimeLine: {
        name: 'Today'
      },
    },
    eventTooltip: {
      disabled: true
    },
  },


  cellEditFeature: false,

  // snap to days
  eventDragFeature: {
    constrainDragToResource: true,
    showExactDropPosition: true
  },

  // Resize snapping to days
  eventResizeFeature: {
    showExactResizePosition: true
  },

  // Shade weekends
  nonWorkingTimeFeature: false,
  // Uses a tree where parent nodes are employees and child nodes are clients
  treeFeature: true,

  columns: [
    {
      type: 'tree',
      text: 'Projects',
      field: 'name',
      width: '20em',
      // Hide default tree icons
      expandedFolderIconCls: null,
      collapsedFolderIconCls: null,
      leafIconCls: null,
      // Set to `false` to render our custom markup
      htmlEncode: false,
      // Custom renderer display employee info or client color + name
      renderer({ record, value, size }) {
        // Parent rows are employees
        if (record.isParent) {
          // Make project row higher
          size.height = 60;
          // console.log(record, value, size)

          // Project template
          return (
            StringHelper.xss`
                         <div style="width: 100%" class = "">
                         <div class = "schedulerParentNode"> <i  class="mr-2 b-fa b-fa-folder ${record.eventColor}--text"></i>${value} <span class="caption mr-2" style="float: right">(${record.numberOfFollowups})</span></div>
                         </div>`
          );
        }
        else {
          // Followup template
          // return StringHelper.xss`<div class="client-color ${record.color
          //   }"></div>${value}`;
          return StringHelper.xss`<span class="schedulerChildNode">${value}</span>`
        }
      }
    },
    // {
    //   text: 'Status',
    //   width: '12em',
    //   field: 'status',
    //   editor: false,
    //   htmlEncodeHeaderText: false,
    //   vue: true,
    //   renderer({ record }) {

    //     // The object needed by the wrapper to render the component
    //     if (record.data.type == "follow_up")
    //     return {
    //       // Required. Name of the component to render.
    //       // The component must be registered globally in main.js
    //       // https://vuejs.org/v2/guide/components.html#Dynamic-Components
    //       is: 'schedulerFollowupStatusDropdown',
    //       record
    //     };
    //   }
    // }

  ],

  // CrudManager loads all data from a single source
  /*  crudManager: {
     autoLoad: true,

     transport: {
       load: {
         url: 'data.json'
       }
     },

     resourceStore: {
       fields: ['color', 'title'],
       tree: true
     },

     eventStore: {
       fields: ['color', 'location']
     },

     // This config enables responses validation and dumping of found errors to the browser console.
     // It's meant to be used as a development stage helper only so please set it to false for production systems.
     validateResponse: true
   }, */

  // Custom event renderer that applies colors and display events location


  listeners: {
    cellClick(e) {
      // console.log(e)
      if (e.column.data.type == "tree") {
        this.scrollToDate(new Date(e.record.startDate), {
          highlight,
          animate: {
            easing: 'easeFromTo',
            duration: 500
          },
          block: "start",
          edgeOffset: 100
        });
      }
    },

    // dragCreateEnd({ newEventRecord, resourceRecord }) {
    //   // Make new event have correct type, to show correct fields in event editor
    //   newEventRecord.type = resourceRecord.isLeaf
    //     ? 'client'
    //     : 'employee';
    // },

    // cellDblClick({ record, cellElement, column }) {
    //   // Show a custom editor when dbl clicking a client cell
    //   if (column.field === 'name' && record.isLeaf) {
    //     new Popup({
    //       autoShow: true,
    //       autoClose: true,
    //       closeAction: 'destroy',
    //       scrollAction: 'realign',
    //       forElement: cellElement,
    //       anchor: true,
    //       width: '20em',
    //       cls: 'client-editor',
    //       items: [
    //         {
    //           type: 'text',
    //           name: 'name',
    //           label: 'Client',
    //           labelWidth: '4em',
    //           value: record.name,
    //           onChange: ({ value }) => {
    //             record.name = value;
    //           }
    //         },
    //         {
    //           type: 'combo',
    //           cls: 'b-last-row',
    //           name: 'color',
    //           label: 'Color',
    //           labelWidth: '4em',
    //           items: colors.map(color => [
    //             color.toLowerCase(),
    //             color
    //           ]),
    //           listItemTpl: data =>
    //             `<div class="color-item ${data.value
    //             }"></div>${data.text}`,
    //           value: record.color,
    //           onChange: ({ value }) => {
    //             record.color = value;
    //           }
    //         }
    //       ]
    //     });
    //   }
    // },

    prio: 1
  }
};

export { schedulerConfig };
