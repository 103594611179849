<template>
  <div>
    <!-- <v-list-group color="white" active-class="white--text" :value="false">
      <template v-slot:activator>
        <v-icon class="mx-4">mdi-folder-text-outline</v-icon>

        <v-list-item-content>
          <v-list-item-title>{{ $t("projects.projects") }}</v-list-item-title>
        </v-list-item-content>
      </template>
      <draggable v-model="projects">
      <v-list-item
        v-for="(project, i) in projects"
        :key="i"
        dark
        dense
        class="mx-8"
        @click="goToProject(i)"
      >
        <v-list-item-title class="white--text"
          ><v-btn icon small width="20px" height="20px" class="mr-2">
            <v-icon :color="project.color" small
              >mdi-checkbox-blank-circle</v-icon
            >
          </v-btn>
          {{ project.name }}</v-list-item-title
        >
      </v-list-item></draggable> -->

    <!-- Add a project -->
    <!-- <v-list-item dense @click.stop="dialog = true" class="mx-8">

        <v-list-item-title> <v-icon class="mr-2">mdi-plus</v-icon>{{
          $t("projects.addNewProject")
        }}</v-list-item-title>
      </v-list-item>
    </v-list-group> -->

    <v-dialog v-model="dialog" max-width="480" transition="slide-x-transition">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          class="ml-6"
          small
          color="button_blue"
          dark

          depressed
          @click="initNewProject"
        >
          <v-icon small class="pr-1">mdi-plus</v-icon>
          {{ $t("projects.addNewProject") }}
        </v-btn>
      </template>
      <template v-slot:default>
        <v-card>
          <v-card-title class="title font-weight-regular justify-space-between">
            <span>{{
              project.id
                ? $t("projects.updateExistingProject")
                : $t("projects.addNewProject")
            }}</span>
            <v-btn icon @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text class="pa-5">
            <v-text-field
              v-model="project.name"
              :label="$t('misc.title')"
              placeholder=""
              filled
              dense
              autocomplete="off"
            />
            <v-subheader>{{ $t("projects.projectColor") }}</v-subheader>
            <v-row wrap no-gutters justify="center">
              <v-btn
                icon
                depressed
                text
                v-for="color in selectableColors"
                :key="color"
                @click="project.color = color"
              >
                <v-icon :large="project.color === color" :color="color">
                  mdi-checkbox-blank-circle
                </v-icon>
              </v-btn>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn text @click="dialog = false" color="almost_black">
              <v-icon class="pr-1" small>mdi-close</v-icon>
              {{ $t("misc.cancel") }}
            </v-btn>
            <v-spacer></v-spacer>

            <v-btn
              color="success"
              class="white--text"
              depressed
              :disabled="project.name == ''"
              @click="saveProject()"
            >
              {{
                project.id
                  ? $t("projects.updateProject")
                  : $t("projects.createProject")
              }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>


<script>
import draggable from "vuedraggable";
import CREATE_PROJECT from "@/graphql/projects/create.gql";
import UPDATE_PROJECT from "@/graphql/projects/update.gql";
import { mapGetters } from "vuex";

export default {
  name: "projectForm",
  mixins: [],
  components: { draggable },
  props: {
    editedProject: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      dialog: false,
      selectableColors: [
        "red",
        "pink",
        "purple",
        "indigo",
        "blue",
        "cyan",
        "teal",
        "green",
        "lime",
        "yellow",
        "orange",
      ],
      project: {
        id: null,
        name: "",
        color: "blue",
      },
    };
  },
  computed: {
    ...mapGetters(["selectedOrganisationId"]),
  },
  methods: {
    saveProject() {
      if (this.project.id) this.updateProject();
      else this.createProject();
    },
    updateProject() {
      this.$apollo
        .mutate({
          mutation: UPDATE_PROJECT,
          variables: {
            id: this.project.id,
            name: this.project.name,
            color: this.project.color,
          },
        })
        .then(({ data }) => {
          this.sendSuccess(this.$t("projects.updatedProject"));
          this.dialog = false;
          this.project.name = "";
          this.project.color = "";
          this.$emit("projectUpdated");
        })
        .catch((error) => {
          this.sendError(error);
          console.log(error);
        });
    },
    createProject() {
      this.$apollo
        .mutate({
          mutation: CREATE_PROJECT,
          variables: {
            name: this.project.name,
            color: this.project.color,
            organisation_id: this.selectedOrganisationId,
          },
        })
        .then(({ data }) => {
          this.sendSuccess(this.$t("projects.created"));
          this.$amplitude.getInstance().logEvent("Create project" , {name: this.project.name, id: data.create_project.id});
          this.dialog = false;
          this.project.name = "";
          this.project.color = "";
          this.$emit("projectCreated", data.create_project);
        })
        .catch((error) => {
          this.sendError(error);
          console.log(error);
        });
    },
    goToProject(index) {},
    initEditProject() {
      if (this.editedProject.id) {
        this.project = {
          id: this.editedProject.id,
          name: this.editedProject.name,
          color: this.editedProject.color,
        };
      }
    },
    initNewProject() {
      this.project = {
        id: null,
        name: "",
        color: "blue",
      };
    },
  },
  created() {
    this.initNewProject();
  },
  watch: {
    editedProject() {
      this.initEditProject();
      this.dialog = true;
    },
    /* dialog(val) {
      if (!val) thi

    } */
  },
};
</script>
