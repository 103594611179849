<template>
  <v-card
    flat
    tile
    v-if="resource.type == 'project' && resource.data"
    color="light_grey"
  >
    <v-app-bar
      :color="resource.data.color"
      dark
      flat
      :key="resource.data.picture_url"
      :src="resource.data.picture_url"
    >
      <template v-slot:img="{ props }">
        <v-img
          v-if="resource.data.picture_url"
          v-bind="props"
          gradient="to top, rgba(0,0,0,.8), rgba(0,0,0,.5)"
        ></v-img>
        <v-img
          v-else
          v-bind="props"
          gradient="to top, rgba(0,0,0,.5), rgba(0,0,0,.5)"
        ></v-img>
      </template>

      <!-- Project Selector -->
      <v-autocomplete
        :value="resource.data.id"
        :items="projects"
        item-value="id"
        item-text="name"
        dense
        hide-details
        solo
        dark
        background-color="transparent"
        flat
        @change="selectProject"
        :key="resource.data.name + resource.data.color"
        class="font-weight-bold"
      >
        <template v-slot:prepend-inner>
          <!-- Update color -->
          <v-menu bottom left>
            <template #activator="menu">
              <v-tooltip left color="black">
                <template #activator="tooltip">
                  <v-btn
                    small
                    icon
                    :color="resource.data.color"
                    v-on="{ ...menu.on, ...tooltip.on }"
                  >
                    <v-icon>mdi-circle</v-icon></v-btn
                  >
                </template>

                <span>{{ $t("projects.changeProjectColor") }}</span>
              </v-tooltip>
            </template>

            <v-list dense subheader>
              <v-subheader>
                {{ $t("projects.projectColor") }}
              </v-subheader>
              <v-list-item
                v-for="(item, i) in selectableColors"
                :key="i"
                @click="updateProjectField('color', item)"
              >
                <v-list-item-title
                  ><v-icon :color="item" small left>mdi-circle</v-icon>
                  {{ $t("misc.colors." + item) }}</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:prepend-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t("projects.projects") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mt-2"></v-divider>
        </template>

        <template v-slot:item="data">
          <v-list-item-icon>
            <customIcon
              :class="data.item.color + '--text'"
              name="project"
              height="16"
              style="position: relative; top: 5px"
              ><iconProject
            /></customIcon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> {{ data.item.name }}</v-list-item-title>
          </v-list-item-content>
        </template>
      </v-autocomplete>
      <v-spacer></v-spacer>

      <!-- Remove banner -->
      <template v-if="resource.data.picture_url">
        <v-tooltip left color="black">
          <template #activator="tooltip">
            <v-btn
              class="mx-2"
              small
              icon
              @click="removeBanner()"
              v-on="{ ...tooltip.on }"
            >
              <v-icon size="20">mdi-image-remove</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("projects.removeProjectBanner") }}</span>
        </v-tooltip>
      </template>

      <!-- Add banner button -->
      <template v-else>
        <uploader v-on:upload="setProjectPictureBlob($event.file, $event.blob)">
          <template #btn="{}">
            <v-tooltip left color="black">
              <template #activator="tooltip">
                <v-btn class="mx-2" small icon v-on="{ ...tooltip.on }">
                  <v-icon size="20">mdi-image-plus</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("projects.projectBanner") }}</span>
            </v-tooltip>
          </template>
        </uploader>
      </template>

      <!-- Close panel -->
      <v-btn icon small class="mx-2" @click="$emit('close')">
        <v-icon size="20">mdi-close</v-icon>
      </v-btn>

      <!-- Tabs -->
      <template v-slot:extension>
        <v-tabs
          align-with-title
          background-color="transparent"
          v-model="selectedTab"
        >
          <v-tabs-slider color="white"></v-tabs-slider>
          <v-tab href="#overview"
            ><v-icon small left>mdi-folder</v-icon
            >{{ $t("projects.projectOverview") }}</v-tab
          >

          <v-tab href="#settings"
            ><v-icon small left>mdi-tune-variant</v-icon
            >{{ $t("projects.projectSettings") }}</v-tab
          >
        </v-tabs>
      </template>
    </v-app-bar>

    <v-card-text style="height: calc(100vh - 196px); overflow-y: auto" >
      <v-tabs-items v-model="selectedTab">
        <v-tab-item value="overview">
          <!-- Project Owner && Users-->
          <v-row>
            <!-- Project Owner -->
            <v-col cols="6">
              <v-card flat class="mb-4" style="height: 96px">
                <v-card-title class="py-2 px-4 body-3 pb-0">
                  {{ $t("projects.owner") }}:
                </v-card-title>
                <v-card-text class="px-1 py-0">
                  <v-list class="py-0" dense>
                    <userItem
                      noAction
                      condensed
                      v-if="resource.data.user"
                      :user="resource.data.user"
                    >
                      <template v-slot:action>
                        <v-btn icon @click="unAssignUserToTask(taskUser.id)">
                          <v-icon color="error" small>mdi-account-minus</v-icon>
                        </v-btn>
                      </template>
                    </userItem></v-list
                  >
                </v-card-text>
              </v-card>
            </v-col>
            <!-- Users -->
            <v-col cols="6">
              <v-card flat class="mb-4" style="height: 96px">
                <v-card-title class="py-2 px-4 body-3">
                  {{ $t("projects.sharedWith") }}: <v-spacer></v-spacer>
                  <userAvatarGroup
                    style="float: left"
                    v-if="resource.data.users.length"
                    :limit="2"
                    :users="resource.data.users"
                    :size="26"
                    :disabledMenu="false"
                  />
                </v-card-title>
                <v-card-text class="px-2 pr-4 py-0">
                  <searchAndSelectUsers
                    :selectedUsersIds="resource.data.users.map((u) => u.id)"
                    :selectedMessage="$t('projects.assignedToProject')"
                    @onUserSelected="addUserToProject"
                    @onUserUnselected="excludeUserFromProject"
                    :disabledIds="
                      resource.data.user ? [resource.data.user.id] : []
                    "
                  />
                  <!-- <v-btn
                    style="float: right"
                    small
                    color="button_blue"
                    dark
                    depressed
                    ><v-icon left small> mdi-share-variant</v-icon
                    >{{ $t("projects.share") }}</v-btn
                  > -->
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <!-- Followups -->
          <v-card flat class="mb-4">
            <v-card-title class="py-2 px-4 body-3">
              {{ $t("navigationMenu.followUps") }} ({{
                resource.data.follow_ups ? resource.data.follow_ups.length : 0
              }}):
              <v-spacer></v-spacer>
              <v-btn depressed small dark color="button_blue" @click="addFollowUpDialog = true">
                <v-icon small left>mdi-plus</v-icon>
                {{ $t("projects.addFollowUp") }}
              </v-btn>
            </v-card-title>
            <v-card-text class="pa-0 pb-2">
              <projectFollowupsTable
                v-if="resource.data.follow_ups"
                :projectId="resource.data.id"
                :followups="resource.data.follow_ups"
                :followUpIdToHighlight="followUpIdToHighlight"
                @updateFollowUp="updateFollowUp"
                @removeFollowUpFromProject="$emit('removeFollowUpFromProject', $event)"
                @editFollowUp="editFollowUp"
              />
            </v-card-text>
          </v-card>

          <!-- Comments -->
          <v-card flat class="mb-4">
            <v-card-title class="py-2 px-4 body-3">
              {{ $t("misc.comment") }} :
            </v-card-title>
            <v-card-text class="px-1 py-0">
              <v-textarea
                :placeholder="$t('projects.writeAComment')"
                v-model="comment"
                filled
                dense
                solo
                flat
                autocomplete="off"
                class="px-2 py-2 mb-2"
                rows="2"
                @change="updateProjectField('comment', $event)"
                hide-details
              />
            </v-card-text>
          </v-card>

          <!-- Milmestones -->
          <!-- <v-card flat class="mb-4">
            <v-card-title class="py-2 px-4 body-3">
              Milestones (0):
              <v-spacer></v-spacer>
              <v-btn depressed small dark color="button_blue">
                <v-icon small left>mdi-plus</v-icon
                >{{ $t("projects.addMilestone") }}</v-btn
              >
            </v-card-title>
            <v-card-text class="px-4">
              <v-alert
                colored-border
                type="info"
                elevation="0"
                class="caption ml-0 pl-0 mb-0"
                prominent
                color=""
              >
                <span class="caption">
                  {{ $t("projects.noMilestonesDesc1") }}<br />
                  {{ $t("projects.noMilestonesDesc2") }}
                </span>

                <template slot="prepend">
                  <v-icon class="mr-4 ml-0" size="28" color="mid_grey"
                    >mdi-information</v-icon
                  >
                </template>
              </v-alert>
            </v-card-text>
          </v-card> -->
        </v-tab-item>
        <v-tab-item value="comments">Comments tab</v-tab-item>
        <v-tab-item value="settings">
          <v-card flat class="mb-4">
            <v-card-title class="py-2 px-4 body-3">
              {{ $t("projects.projectSettings") }}
            </v-card-title>
            <v-card-text class="px-4">
              <v-row wrap no-gutters>
                <!-- Start Date -->
                <v-col cols="6" class="px-2">
                  <v-menu
                    ref="show_start_date_menu"
                    v-model="show_start_date_menu"
                    :close-on-content-click="false"
                    :return-value.sync="start_date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="
                          start_date == todaysDate
                            ? $t('misc.today')
                            : start_date
                        "
                        :label="$t('projects.projectStartDate')"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        filled
                        dense
                        hide-details
                        class="mb-4"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="start_date"
                      no-title
                      scrollable
                      @change="updateStartDate"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <!-- End date -->
                <v-col cols="6" class="px-2">
                  <v-menu
                    ref="show_end_date_menu"
                    v-model="show_end_date_menu"
                    :close-on-content-click="false"
                    :return-value.sync="end_date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="
                          end_date == todaysDate ? $t('misc.today') : end_date
                        "
                        :label="$t('projects.projectEndDate')"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        filled
                        dense
                        hide-details
                        class="mb-4"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="end_date"
                      no-title
                      scrollable
                      @change="updateEndDate"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-text-field
                v-model="name"
                :label="$t('projects.projectName')"
                placeholder=""
                filled
                dense
                autocomplete="off"
                class="px-2 mb-4"
                @change="updateProjectField('name', $event)"
                hide-details
              />

              <v-textarea
                v-model="description"
                :label="$t('misc.description')"
                placeholder=""
                filled
                dense
                autocomplete="off"
                class="px-2 mb-2"
                rows="2"
                @change="updateProjectField('description', $event)"
                hide-details
              />
            </v-card-text>
          </v-card>

          <v-card flat class="mb-4">
            <!-- <v-card-title class="py-2 px-4 body-3">
              <v-icon left small color="error">mdi-alert</v-icon
              >{{ $t("misc.dangerZone") }}:
            </v-card-title> -->
            <v-card-text class="px-4">
              <v-btn
                @click="$emit('deleteProject', resource.data)"
                block
                depressed
                color="error"
                >{{ $t("projects.deleteProject") }}</v-btn
              >
            </v-card-text></v-card
          >
        </v-tab-item>
      </v-tabs-items>

      <!-- <v-col cols="8">
        <h1>PARTIE USERS</h1>
        <div>
          Project Owner :
          <userAvatar
            v-if="resource.data.user"
            :size="20"
            :show_name_in_tooltip="true"
            :first_name="resource.data.user.first_name"
            :last_name="resource.data.user.last_name"
            :avatar_url="resource.data.user.avatar_url"
            :email="resource.data.user.email"
          />
        </div> -->
      <!-- <div>
          Project Users :
          <userAvatarGroup
            v-if="resource.data.users.length"
            :limit="2"
            :users="resource.data.users"
            :size="22"
            :disabledMenu="false"
          />
        </div>
        <div>

        </div> -->
      <!-- </v-col> -->
    </v-card-text>
    <v-dialog v-model="editFollowUpDialog" max-width="480" transition="dialog-top-transition">
      <template v-slot:default>
        <followUpForm
          @followUpUpdated="onFollowUpUpdated"
          @closeDialog="editFollowUpDialog = false"
          :hideProjectField="true"
          :hideOwnerField="true"
          :editedFollowup="editedFollowUp"
          :projects="projects"
          :projectsLoading="false"
        />
      </template>
    </v-dialog>
    <v-dialog v-model="addFollowUpDialog" max-width="480" transition="dialog-top-transition">

        <addFollowUp
          v-show="addFollowUpDialog"
          :projectId="resource.data.id"
          @closeDialog="onFollowUpCreated"
          @cancel="addFollowUpDialog = false"
          @updateFollowUp="updateFollowUp"
          @addFollowUpToProject="$emit('addFollowUpToProject', $event)"
        />

    </v-dialog>
    <!-- <followUpForm
      @followUpCreated="onFollowUpCreated"
      :editedFollowup="editedFollowup"
    /> -->
  </v-card>
</template>

<script>
import date from "@/mixins/date";
import users from "@/mixins/users";
import customIcon from "@/components/custom_icons/customIcon";
import iconFollowup from "@/components/custom_icons/iconFollowup";
import iconProject from "@/components/custom_icons/iconProject";
import searchAndSelectUsers from "./../users/searchAndSelectUsers";
import userAvatarGroup from "@/components/shared/userAvatarGroup";
import userAvatar from "../shared/userAvatar";
import userItem from "@/components/users/userItem";
import CREATE_PROJECT_USER from "@/graphql/project_users/create.gql";
import uploader from "@/components/shared/uploader";
import REMOVE_PROJECT_PICTURE from "@/graphql/projects/remove_picture.gql";
import { mapActions } from "vuex";
import followup_status from "@/mixins/followup_status.js";
import projectFollowupsTable from "./project_followups_table";
import followUpForm from "@/components/followups/followUpForm";
import AddFollowUp from './addFollowUp.vue';

export default {
  name: "schedulerSidebar",
  components: {
    AddFollowUp,
    followUpForm,
    customIcon,
    iconFollowup,
    iconProject,
    uploader,
    userAvatarGroup,
    userAvatar,
    searchAndSelectUsers,
    projectFollowupsTable,
    userItem,
  },
  mixins: [date, users, followup_status],
  props: {
    resource: {
      type: Object,
      required: true,
    },
    projects: {
      type: Array,
      required: true,
    },
    followUpIdToHighlight: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      editedFollowUp: {},
      editFollowUpDialog: false,
      addFollowUpDialog: false,
      name: "",
      description: "",
      color: "blue",
      todaysDate: new Date().toISOString().substr(0, 10),
      start_date: new Date().toISOString().substr(0, 10),
      end_date: new Date().toISOString().substr(0, 10),
      color: "",
      comment: "",
      show_start_date_menu: false,
      show_end_date_menu: false,
      selectedTab: 0,
      selectableColors: [
        "red",
        "pink",
        "purple",
        "indigo",
        "blue",
        "cyan",
        "teal",
        "green",
        "lime",
        "yellow",
        "orange",
      ],
    };
  },
  computed: {},
  methods: {
    ...mapActions(["openDestroyDialog"]),
    onFollowUpCreated(vars) {
      this.addFollowUpDialog = false;
      this.$emit('followUpCreated');
    },
    onFollowUpUpdated(vars) {
      this.editFollowUpDialog = false;
      console.log("TODO rehydrate");
      this.updateFollowUp(vars);
      //this.refreshIndex++;
    },
    editFollowUp(followup) {
      console.log('followup edited:', followup);
      this.editedFollowUp = {};
      this.$nextTick(() => {
        this.editedFollowUp = followup;
        this.editFollowUpDialog = true;
      });
    },
    projectUserIdByUserId(userId) {
      return this.resource.data.project_users.find((e) => e.user_id === userId)
        .id;
    },
    addUserToProject(user) {
      console.log("add user to project");
      this.$apollo
        .mutate({
          mutation: CREATE_PROJECT_USER,
          variables: {
            user_id: user.id,
            project_id: this.resource.data.id,
          },
        })
        .then(({ data }) => {
          this.$emit("rehydrateProject", data.create_project_user.project);
        })
        .catch((error) => {
          this.sendError(error);
          console.log(error);
        });
    },
    excludeUserFromProject(user) {
      this.openDestroyDialog({
        id: this.projectUserIdByUserId(user.id),
        model: "project_user",
        onDestroy: () => {
          this.$emit("removeUserFromProject", {
            project_id: this.resource.data.id,
            user_id: user.id,
          });
        },
        title: this.$t("projects.removeMemberAlertTitle"),
        message: this.$t("projects.removeMemberAlertMessage")
          .replace("<project_name>", this.resource.data.name)
          .replace("<user_name>", this.userName(user)),
      });
    },
    updateStartDate() {
      this.$refs.show_start_date_menu.save(this.start_date);
      console.log("emited start date", this.start_date);
      this.updateProjectField("start_date", this.start_date);
    },
    updateEndDate() {
      this.$refs.show_end_date_menu.save(this.end_date);
      console.log("emited end date", this.end_date);
      this.updateProjectField("end_date", this.end_date);
    },
    updateProjectField(field, value) {
      console.log(field, value);
      let vars = { id: this.resource.data.id };
      vars[field] = value;
      this.$emit("updateProject", vars);
    },
    updateFollowUp(vars) {
      this.$emit("updateFollowUp", vars);
    },
    loadProject() {
      this.name = this.resource.data.name;
      this.start_date = this.resource.data.start_date;
      this.end_date = this.resource.data.end_date;
      this.description = this.resource.data.description;
      this.comment = this.resource.data.comment;
    },
    selectProject(e) {
      this.$emit("selectProject", e);
    },
    setProjectPictureBlob(file, blob) {
      let vars = {
        id: this.resource.data.id,
        picture_signed_blob_id: blob.signed_id,
      };
      this.$emit("updateProject", vars);
    },
    removeBanner() {
      this.$apollo
        .mutate({
          mutation: REMOVE_PROJECT_PICTURE,
          variables: { id: this.resource.data.id },
        })
        .then(({ data }) => {
          this.resource.data.picture_url = null;
        });
    },
  },
  mounted() {
    this.loadProject();
  },
  created() {},
  watch: {
    resource() {
      this.loadProject();
    },
    selectedTab() {
      // Avoid retriggering followup highlight effect when switching tabs.
      this.$emit("clearHighlightedFollowUp");
    },
  },
};
</script>





<style scoped>
</style>