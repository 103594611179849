<template>
  <v-card class="mx-auto" width="500">
    <v-card-title class="title font-weight-regular justify-space-between">
      {{ currentTitle }}
      <v-btn icon @click="cancel()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-window v-model="step">
      <v-window-item :value="0">
        <v-card-text class="text-center justify-center align-center">
          <v-row wrap no-gutters>
            <v-col cols="12" class="py-2">
              <v-btn
                depressed
                small
                dark
                color="amber darken-2"
                @click="step = 2"
              >
                <v-icon small left>mdi-arrow-down-bold-box</v-icon>
                {{ $t("followUps.selectExistingFollowUp") }}
              </v-btn>
            </v-col>
            <v-col cols="12" class="py-2 caption"> {{ $t("misc.or") }}</v-col>
            <v-col cols="12" class="py-2">
              <v-btn depressed small dark color="button_blue" @click="step = 1">
                <v-icon small left>mdi-plus</v-icon>
                {{ $t("followUps.createANewFollowUp") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-window-item>

      <v-window-item :value="1">
        <v-card-text>
          <v-text-field
            v-model="followUp.name"
            :label="$t('misc.title')"
            placeholder=""
            filled
            dense
            autocomplete="off"
          />
          <v-textarea
            v-model="followUp.description"
            :label="$t('misc.description')"
            placeholder=""
            filled
            dense
            autocomplete="off"
          />
        </v-card-text>
      </v-window-item>

      <v-window-item :value="2">
        <div class="pa-4 text-center">
          <span class="text-caption grey--text">
            <paginatedTable
              :headers="headers"
              :graphqlQuery="getGraphqlQuery()"
              :refresh="refreshIndex"
              resourceName="follow_ups"
              :filter="filter"
              useCustomEmptySlot
              :customSlots="['name', 'action']"
              cursorAsPointer
              @noResourceFetched="noResourceFetched = true"
            >
              <template v-slot:name="{ slotScope: { item } }">
                <strong>{{ item.name }}</strong>
              </template>

              <template v-slot:action="{ slotScope: { item } }">
                <v-btn
                  depressed
                  small
                  dark
                  color="button_blue"
                  @click="updateFollowUpProject(item.id)"
                >
                  <v-icon small left>mdi-plus</v-icon>
                  Add follow Up to project
                </v-btn>
              </template>
            </paginatedTable>
          </span>
        </div>
      </v-window-item>
    </v-window>

    <v-divider></v-divider>

    <v-card-actions>
      <v-btn :disabled="step === 0" text @click="step = 0"> Back </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        v-if="step === 1"
        :disabled="step === 2"
        color="primary"
        depressed
        @click="createFollowUp"
      >
        Create
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import PAGINATED_FOLLOW_UPS_INDEX from "@/graphql/follow_ups/paginated_index.gql";
import paginatedTable from "@/components/shared/paginatedTable";
import UPDATE_FOLLOW_UP from "@/graphql/follow_ups/update.gql";
import CREATE_FOLLOW_UP from "@/graphql/follow_ups/create.gql";
import { mapGetters } from "vuex";

export default {
  components: { paginatedTable },
  data: () => ({
    actionSelected: null,
    step: 0,
    followUp: {
      name: "",
      description: "",
    },
    refreshIndex: 0,
  }),
  props: {
    projectId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["selectedOrganisationId"]),
    headers() {
      return [
        {
          text: this.$t("navigationMenu.followUps"),
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: this.$t("misc.actions"),
          align: "end",
          sortable: false,
          value: "action",
        },
      ];
    },
    filter() {
      let filter = `follow_ups.organisation_id == '${this.selectedOrganisationId}'`;
      filter += `&& project_id === null`;
      /*  if (this.search) {
        filter += `&& (follow_ups.name like '%${this.search}%' || follow_ups.description like '%${this.search}%')`;
      } */
      return filter;
    },
    currentTitle() {
      switch (this.step) {
        case 0:
          return "Add Follow Up";
        case 1:
          return "Create Follow Up";
        case 2:
          return "Import Follow Up";
        default:
          return "Follow Up added";
      }
    },
  },
  methods: {
    cancel() {
      this.step = 0;
      this.followUp.name = "";
      this.followUp.description = "";
      this.$emit("cancel");
    },
    getGraphqlQuery() {
      return PAGINATED_FOLLOW_UPS_INDEX;
    },
    finish() {
      this.step = 0;
      this.$emit("closeDialog");
    },
    updateFollowUpProject(follow_up_id) {
      let vars = {
        project_id: this.projectId,
        id: follow_up_id,
      };
      this.$apollo
        .mutate({
          mutation: UPDATE_FOLLOW_UP,
          variables: vars,
        })
        .then(({ data }) => {
          this.$emit("addFollowUpToProject", {
            follow_up: data.update_follow_up,
            project_id: this.projectId,
          });
          this.step = 0;
          this.$emit("closeDialog");
        })
        .catch((error) => {
          this.sendError(error);
          console.log(error);
        });
    },
    createFollowUp() {
      this.$apollo
        .mutate({
          mutation: CREATE_FOLLOW_UP,
          variables: {
            name: this.followUp.name,
            description: this.followUp.description,
            project_id: this.projectId,
            organisation_id: this.selectedOrganisationId,
          },
        })
        .then(({ data }) => {
          this.finish();
          this.sendSuccess(this.$t("followUps.created"));
          this.followUp.name = "";
          this.followUp.description = "";
          this.$emit("addFollowUpToProject", {
            follow_up: data.create_follow_up,
            project_id: this.projectId,
          });
        })
        .catch((error) => {
          this.sendError(error);
          console.log(error);
        });
    },
  },
  watch: {
    step() {
      if (this.step === 2) this.refreshIndex++;
    },
  },
};
</script>